.App {
  box-sizing: border-box;
  color: black;
  color: hsl(0, 0%, 20%);
  margin: 0 auto;
  max-width: 600px;
  text-align: center;
}

.header-wrapper {
  width: 100%;
}

.header {
  display: flex;
  flex-wrap: nowrap;
  margin: 20px auto;
  max-width: 300px;
  width: 100%;
}

.flex-justify-spacebetween {
  justify-content: space-between;
}

.flex-justify-center {
  justify-content: center;
}

.player {
  border: 1px solid;
  border-color: lightblue;
  border-radius: 5px;
  font-weight: bold;
  font-size: 1.1em;
  padding: 0.5em 0.75em;
}

.active-player {
  font-color: white;
  background-color: lightblue;
}

.win-msg {
  background-color: gold;
  color: white;
}

.draw-msg {
  background-color: lightgrey;
  color: white;
}

.game-wrapper {
  width: 100%;
}

.game {
  border: 0;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  margin: 0 auto;
  padding: 0;
  width: max-content;
}

.field-row {
  display: flex;
  margin: 0;
  padding: 0;
  width: 100%;
}

.field {
  background-color: hsla(0, 0%, 0%, 0%);
  border: 1px solid black;
  border: 1px solid hsl(0, 0%, 20%);
  font-size: 1.2em;
  height: 100px;
  width: 100px;
}

.field:disabled {
  color: inherit;
}

.field.winning {
  background-color: gold;
}

.field-row:first-of-type > .field {
  border-top: 0;
  margin-top: 0;
}

.field-row:last-of-type > .field {
  border-bottom: 0;
  margin-bottom: 0;
}

.field-row > .field:first-of-type {
  border-left: 0;
  margin-left: 0;
}

.field-row > .field:last-of-type {
  border-right: 0;
  margin-right: 0;
}

.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
   -khtml-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.reset-button {
  background-color: gray;
  border: 0;
  border-radius: 5px;
  color: white;
  font-size: 1.1em;
  font-weight: bold;
  margin: 20px auto;
  padding: 0.5em 0.75em;
}